<template>
  <div>
    <BasicNewContainer
        :title="t('create.title')"
        ref="editor"
        @cancel="close"
        :saveAction="saveItem"
        @saved="onSaved"
        :has-changes="hasChanges">
      <expense-types-content v-model="item"/>
    </BasicNewContainer>
  </div>
</template>

<script>
import {HTTP} from '@/auth'
import BasicNewContainer from "@/components/inputs/BasicNewContainer";
import ExpenseTypesContent from "@/views/ExpenseTypesContent.vue";

export default {
  name: 'ExpenseTypesCreate',
  components: {
    ExpenseTypesContent,
    BasicNewContainer,
  },
  data() {
    return {
      item: {
        requires_receipt: false,
        is_reimbursed: false,
        unit: '',
        employee_factor: 0,
        calculation_type_code: 'AT_COST',
      },
      itemOriginal: null,
    }
  },
  created() {
    this.itemOriginal = JSON.parse(JSON.stringify(this.item));
  },
  mounted() {
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.item) !== JSON.stringify(this.itemOriginal);
    },
  },
  methods: {
    t: function (key) {
      return this.$t('expense-type.detail.' + key);
    },
    saveItem() {
      return HTTP.post('expense-types', this.item);
    },
    async onSaved() {
      this.dataOriginal = null;
      this.close();
    },
    close() {
      this.$router.push({name: 'ExpenseTypesIndex'})
    }
  }
}
</script>

<style scoped lang="scss">

</style>
